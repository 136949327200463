import React, { useState, useEffect } from 'react';
import './App.scss';
import paasLogo from './assets/PAS-Logo-2016-Tx.png';
import api from './services/api';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";


function App({ msalInst }) {

  const [qrCode, setQrCode] = useState();
  const [randomImage, setRandomImage] = useState();
  const [accountName, setAccountName] = useState();

  function IPtoNum(ip) {
    return Number(
      ip.split(".")
        .map(d => ("000" + d).substr(-3))
        .join("")
    );
  }

  useEffect(() => { 

    const whiteList = [
      ["206.47.33.152", "206.47.33.159"],
      ["216.200.62.48", "216.200.62.55"],
      ["38.122.101.72", "38.122.101.79"],
      ["38.117.84.192", "38.117.84.223"],
      ["204.101.124.96", "204.101.124.127"]
    ];

    // handle auth redired/do all initial setup for msal
    msalInst.handleRedirectPromise().then(async authResult => {

      let response = await fetch('https://api.ipify.org/?format=json').then(x => x.json());
      //console.log(response.ip);
      
      let clientIp = response.ip;
      //console.log(clientIp);

      let inRange = whiteList.some(
        ([min, max]) => IPtoNum(min) <= IPtoNum(clientIp) && IPtoNum(max) >= IPtoNum(clientIp)
      );
      //console.log(inRange);

      // Check if user signed in 
      const account = msalInst.getActiveAccount();
      if (!account) {

        if (!inRange) {
          // redirect anonymous user to login page 
          msalInst.loginRedirect();
        }
        else {
          getQrCode();
          getRandomImage();
        }
      }
      else {
        setAccountName(account.username);
        getQrCode();
        getRandomImage();
      }
    }).catch(err => {
      // TODO: Handle errors
      console.log(err);
    });
  }, [msalInst])

  async function getQrCode() {
    let response = await api.get('https://paas-ironwifi.azurewebsites.net/api/wifiqrcode?mode=dynamic', { responseType: 'blob' });
    setQrCode(URL.createObjectURL(response.data));
  }

  async function getRandomImage() {
    let response = await api.get('https://paas-ironwifi.azurewebsites.net/api/randomPassImage', { responseType: 'blob' });
    setRandomImage(URL.createObjectURL(response.data));
  }

  return (
    <MsalProvider instance={msalInst}>
      <div className="App">
        <div className="welcome">
          <img id="paaslogo" alt="PAAS-Logo" src={paasLogo} />
          <h1>Welcome to Pan American Silver.</h1>
          <h2>Please scan this QR Code to connect to our wifi.</h2>
          <img id="qrcode" alt="QRCode" src={qrCode} />
          WIFI Passcode <img id="randomImage" alt="RandomImage" src={randomImage} />
        </div>
        <div id="userDetail">
          <label>{accountName}</label>
        </div>
      </div>
    </MsalProvider>
  );
}

export default App;

