import axios from "axios";

const api = axios.create();

api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

api.interceptors.request.use(async config => {
    //config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['x-functions-key'] = '-byGyRg3exVA9wpOlny55BcXkhlBGZzsUWOJ102VsDrkAzFuzxRjnQ==';
    return config;
});

export default api;